import React, { useContext } from 'react';
import { SprayCanContext } from '@cof/graffiti-alley-spray-cans/context/app-context';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { WebHeader, Link, Icon, LayoutWrapper } from '@cof/lighthouse-component-library';
import PrivacyPolicyNotification from '../PrivacyBannerLH';
import { getCorrelationId } from '../../lib/correlationId';
import PdpFooterLH from '../Pdp/PdpFooterLH';
import './QuickCheckLayoutLH.scss';

const QuickCheckLayout = injectIntl(({ intl, children, pageName }) => {
  const { setCorrelationId } = useContext(SprayCanContext);
  React.useEffect(() => setCorrelationId(getCorrelationId()), [setCorrelationId]);
  const originalPath = global.window && window.___gatsbyIntl ? window.___gatsbyIntl.originalPath : '/';
  const bypassBlockProps = {
    mainContentIdForBypass: 'main',
    textForBypass: intl.formatMessage({ id: `navheader.bypass-block` }),
  };

  return (
    <div className="qk-redesign-layout page-layout" data-for={pageName}>
      <PrivacyPolicyNotification />
      <LayoutWrapper className="qk-page-web-header" hideVerticalGap>
        <WebHeader bypassBlockProps={bypassBlockProps} showMenuButtonOnMobile={false}>
          <ul>
            <li id="security-qk-web-header" className="menu-align-right qk-security-lock-icon">
              <Link
                data-testid="security-qk-web-header"
                to={intl.formatMessage({ id: 'navheader.link.security.url' })}
                newTab={true}
              >
                <Icon name="lock" className="lock-icon" isDecorative />
                <span className="qk-security-link">{intl.formatMessage({ id: 'navheader.link.security.label' })}</span>
              </Link>
            </li>
            <li className="language-toggle" data-testid="qk-lang-toggle">
              <Link to={originalPath} language={intl.locale === 'en' ? 'fr' : 'en'}>
                <FormattedMessage id={`navheader.link.toggle-lang-${intl.locale === 'en' ? 'fr' : 'en'}`} />
              </Link>
            </li>
          </ul>
        </WebHeader>
      </LayoutWrapper>
      <main id="main">{children}</main>
      <PdpFooterLH intl={intl} hidePromo={true} isQuickCheck={true} />
    </div>
  );
});

QuickCheckLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  className: PropTypes.string,
  intl: intlShape,
};

QuickCheckLayout.defaultProps = {
  pageName: '',
  className: '',
};

export default QuickCheckLayout;
