import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { LayoutWrapper, Footer as PdpFooterRaw } from '@cof/lighthouse-component-library';

import WebFooterDefaultText from '../../WebFooterDefaultText';
import { getNavLinks, getQuickCheckNavBarLinks } from '../../../lib/utils';
import { useConfig } from '../../../hooks/useConfig';

import './PdpFooterLH.scss';

const PdpFooterLH = ({ intl, hidePromo, promoSuper, isQuickCheck }) => {
  const config = useConfig(intl.locale);
  return (
    <LayoutWrapper className="lh-pdp-footer" hideVerticalGap>
      <PdpFooterRaw
        data-testid="pdp-footer-lh"
        className={intl.locale === 'fr' ? 'french-footer' : ''}
        promoLink="https://creditkeeper.capitalone.ca/"
        nav={isQuickCheck ? [] : getNavLinks(intl, config.dapp)}
        hidePromo={hidePromo}
        copyrightText={<WebFooterDefaultText />}
        language={intl.locale}
        promoSuper={promoSuper}
        hideSocial={isQuickCheck ?? true}
        navBar={isQuickCheck ? getQuickCheckNavBarLinks(intl) : []}
      />
    </LayoutWrapper>
  );
};

PdpFooterLH.propTypes = {
  intl: intlShape,
  hidePromo: PropTypes.bool,
  promoSuper: PropTypes.number,
  isQuickCheck: PropTypes.bool,
};

PdpFooterLH.defaultProps = {
  hidePromo: false,
  promoSuper: 3,
  isQuickCheck: false,
};

export default PdpFooterLH;
