import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './IconSetPdp.scss';

const IconSetPdp = ({ children, dividers }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    renderDividers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderDividers = () => {
    const tempArray = [];
    children.forEach((e, i) => {
      if (i !== children.length - 1) {
        tempArray.push(React.createElement(e.type, { ...e.props, key: `${e.props.caption} ${e.props.subCaption}` }));
        tempArray.push(
          React.createElement(
            'div',
            { className: dividers ? 'divider' : 'divider invisible', key: `${i}-divider`, 'data-testid': 'divider' },
            null,
          ),
        );
      } else {
        tempArray.push(React.createElement(e.type, { ...e.props, key: `${e.props.caption} ${e.props.subCaption}` }));
      }
    });
    setItems(tempArray);
  };
  return (
    <div className="icon-set-wrapper" data-testid="icon-set-wrapper">
      {items.length && items}
    </div>
  );
};

IconSetPdp.propTypes = {
  /** IconPdp children */
  children: PropTypes.node.isRequired,
  /** enable or disable the dividers between icons */
  dividers: PropTypes.bool,
};

IconSetPdp.defaultProps = {
  dividers: true,
};

export default IconSetPdp;
