import { useStaticQuery, graphql } from 'gatsby';
import { useState, useEffect } from 'react';
import { DAPP_URL, LANGUAGES, QK_URL } from '../lib/constants';
import { addQSIfNeeded } from '../lib/urls';
import { getConfig } from '../lib/config';
import { sendErrorToNewRelic } from '../lib/newrelicSpa';

const FRENCH_QS_MAP = {
  key: 'lang',
  value: LANGUAGES.FRENCH_CANADA,
};

/**
 * @param {('en' | 'fr')} locale
 */
export const useConfig = (locale) => {
  const [config, setConfig] = useState({
    qk: locale === 'fr' ? addQSIfNeeded({ url: QK_URL, ...FRENCH_QS_MAP }) : QK_URL,
    dapp: DAPP_URL,
    isReady: false,
  });

  const data = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
        }
      }
    `,
  );

  useEffect(() => {
    const qkUrl = addQSIfNeeded({
      url: config.qk,
      ...FRENCH_QS_MAP,
    });

    if ((locale === 'en' && config.qk === qkUrl) || (locale === 'fr' && config.qk !== qkUrl)) {
      setConfig((prev) => ({
        ...prev,
        qk: qkUrl,
      }));
    }
  }, [config.qk, locale]);

  useEffect(() => {
    if (!config.isReady && data?.site?.pathPrefix != null) {
      getConfig(data.site.pathPrefix)
        .then((parsedData) => {
          if (parsedData?.qk && parsedData?.dapp) {
            setConfig(() => ({ ...parsedData, isReady: true }));
          }
        })
        .catch((err) => {
          sendErrorToNewRelic(err);
        });
    }
  }, [data?.site?.pathPrefix, config.isReady]);

  return config;
};
