import { sendErrorToNewRelic } from './newrelicSpa';

export const getConfig = async (pathPrefix = '') => {
  if (typeof window === 'undefined') {
    return;
  }
  return fetch(`${pathPrefix}/config.json`)
    .then((data) => data.json())
    .catch((err) => {
      sendErrorToNewRelic(err);
    });
};
