import React from 'react';
import PropTypes from 'prop-types';
import './IconPdp.scss';

const IconPdp = ({ caption, subCaption, bolded, icon, iconAlt, captionSuper, subCaptionSuper, subCaptionNowrap }) => {
  return (
    <div className="icon-wrapper" data-testid="icon-pdp-wrapper">
      <img className="icon-image" src={icon} alt={iconAlt}></img>
      <p className={bolded === 'top' ? 'icon-paragraph bolded' : 'icon-paragraph'} data-testid="caption">
        {caption}
        {captionSuper && <sup>{captionSuper}</sup>}
      </p>
      <p className={bolded === 'bottom' ? 'icon-paragraph bolded' : 'icon-paragraph'} data-testid="sub-caption">
        {subCaption}
        {subCaptionNowrap && <span className="nowrap">{subCaptionNowrap}</span>}
        {subCaptionSuper && <sup>{subCaptionSuper}</sup>}
      </p>
    </div>
  );
};

IconPdp.propTypes = {
  /** content text */
  caption: PropTypes.string.isRequired,
  /** content text */
  subCaption: PropTypes.string.isRequired,
  /** content text */
  captionSuper: PropTypes.string,
  /** content text */
  subCaptionSuper: PropTypes.string,
  /** content text */
  bolded: PropTypes.oneOf(['top', 'bottom']),
  /** Image */
  icon: PropTypes.string.isRequired,
  /** Image alt text */
  iconAlt: PropTypes.string.isRequired,
  /** content text */
  subCaptionNowrap: PropTypes.string,
};

IconPdp.defaultProps = {
  bolded: 'top',
  captionSuper: '',
  subCaptionSuper: '',
  subCaptionNowrap: '',
};

export default IconPdp;
