import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { Snackbar, Link } from '@cof/lighthouse-component-library';

import './PrivacyBannerLH.scss';

const threeMonth = 1000 * 60 * 60 * 24 * 30 * 3;

const PrivacyBannerLH = ({ intl }) => {
  const expirationDate = new Date(new Date().getTime() + threeMonth);
  const SnackbarMessage = (
    <>
      {intl.formatMessage({ id: 'privacy.warning-info' })}
      {intl.formatMessage({ id: 'privacy.cookie-agreement' })}{' '}
      {intl.locale === 'en' ? (
        <>
          <Link to="/privacypolicy/" data-testid="privacy-policy-terms">
            {intl.formatMessage({ id: 'privacy.link.terms' })}
          </Link>{' '}
          {intl.formatMessage({ id: 'privacy.cookie-continue-usage' })}{' '}
        </>
      ) : (
        <>
          {intl.formatMessage({ id: 'privacy.cookie-continue-usage' })}{' '}
          <Link to="/privacypolicy/" data-testid="privacy-policy-terms">
            {intl.formatMessage({ id: 'privacy.link.terms' })}
          </Link>
          .{' '}
        </>
      )}
      <Link to="/privacypolicy/#oba" native="true" data-testid="privacy-policy-disable">
        {intl.formatMessage({ id: 'privacy.link.disable-cookie' })}
      </Link>
      .
    </>
  );

  return (
    <div id="privacy-banner-lh" data-testid="privacy-banner-lh" className="privacy-banner-snackbar">
      <Snackbar
        id="lh-warning-test"
        data-testid="snackbar-wrapper"
        buttonLabel="close"
        type="warning"
        path="/"
        expires={expirationDate}
      >
        <span className="privacy-banner-snackbar-message">{SnackbarMessage}</span>
      </Snackbar>
    </div>
  );
};

PrivacyBannerLH.propTypes = {
  intl: intlShape.isRequired,
};

PrivacyBannerLH.displayName = 'PrivacyBannerLH';

export default injectIntl(PrivacyBannerLH);
