import React, { useContext } from 'react';
import { SprayCanContext } from '@cof/graffiti-alley-spray-cans/context/app-context';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import PrivacyPolicyNotification from '../PrivacyBanner';
import WebHeader from '@cof/graffiti-alley-spray-cans/organisms/WebHeader';
import WebFooter from '@cof/graffiti-alley-spray-cans/organisms/WebFooter';
import { Menu, MenuItem } from '@cof/graffiti-alley-spray-cans/molecules/Menu';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import { LogoLink } from '../Header/Header';
import { getCorrelationId } from '../../lib/correlationId';
import IncidentBanner from '../IncidentBanner';

const QuickCheckLayout = injectIntl(({ intl, children, pageName, shouldDisplayIncidentBanner }) => {
  const { setCorrelationId } = useContext(SprayCanContext);
  React.useEffect(() => setCorrelationId(getCorrelationId()), [setCorrelationId]);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { originalPath = '/' } = global.window ? window?.___gatsbyIntl : {};

  return (
    <div className="quickcheck-layout page-layout" data-for={pageName}>
      <PrivacyPolicyNotification />
      {shouldDisplayIncidentBanner && <IncidentBanner path={pageName} />}
      <WebHeader logoLink={<LogoLink />} mainContentIdForBypass="main">
        <Menu align="right">
          <MenuItem className="language-toggle">
            <Link to={originalPath} language={intl.locale === 'en' ? 'fr' : 'en'}>
              <FormattedMessage id={`navheader.link.toggle-lang-${intl.locale === 'en' ? 'fr' : 'en'}`} />
            </Link>
          </MenuItem>
        </Menu>
      </WebHeader>
      <main id="main">{children}</main>
      <WebFooter showSocialMenu={false} showNavBar={true} />
    </div>
  );
});

QuickCheckLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  className: PropTypes.string,
  intl: intlShape,
  shouldDisplayIncidentBanner: PropTypes.bool,
};

QuickCheckLayout.defaultProps = {
  pageName: '',
  className: '',
  shouldDisplayIncidentBanner: false,
};

export default QuickCheckLayout;
