import React from 'react';
import { NaturalSelectionSoloMode } from '@cof/natural-selection';

import { getCorrelationId } from '../lib/correlationId';
import SEO from '../components/SEO/SEO';
import QuickCheckComponent from '../components/QuickCheckComponent/QuickCheckComponent';
import QuickCheckRedesignComponent from '../components/QuickCheckRedesignComponent/QuickCheckRedesignComponent';

const { NS, Control, Variation } = NaturalSelectionSoloMode;

const QuickCheckWelcomePage = () => {
  return (
    <NS experimentName="qk-redesign" correlationId={getCorrelationId()}>
      <Control weight={0.5}>
        <QuickCheckComponent />
      </Control>
      <Variation name="redesign" weight={0.5}>
        <QuickCheckRedesignComponent />
      </Variation>
    </NS>
  );
};

export default QuickCheckWelcomePage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.quickcheck.page-title" descriptionKey="pages.quickcheck.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
