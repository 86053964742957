import React, { useEffect, useState, useRef } from 'react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import { getContentKeyUtil } from '../../lib/utils';
import { addQSIfNeeded } from '../../lib/urls';
import QuickCheckLayoutLH from '../../components/QuickCheckLayoutLH';
import SEOHelmet from '../../components/SEO/SEOHelmet';
import newrelicPageAction from '../../lib/newrelicSpa';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import cuid from 'cuid';
import { useConfig } from '../../hooks/useConfig';

import QuickCheckCards from '../../assets/images/quick-check-welcome-page-card-art.png';
import './QuickCheckRedesignComponent.scss';
import { LANGUAGES } from '../../lib/constants';
import { LayoutWrapper, TextBlock, AccordionItem, Link } from '@cof/lighthouse-component-library';
import IconSetPDP from '../../components/Pdp/IconSetPdp';
import IconPDP from '../../components/Pdp/IconPdp';
import qkIconStepOne from '../../assets/images/qk-notes.svg';
import qkIconStepTwo from '../../assets/images/qk-pre-qualify.svg';
import qkIconStepThree from '../../assets/images/qk-credit-card.svg';
import marketingSectionImage from '../../assets/images/quick-check-marketing-desktop.png';

export const getUrlWithNewCorrelationId = (url) => {
  const newCorrelationId = cuid();

  return addQSIfNeeded({
    url,
    key: 'corid',
    value: newCorrelationId,
  });
};

const QuickCheckRedesignComponent = injectIntl(({ intl }) => {
  const config = useConfig(intl.locale);

  const [quickCheckTargetUrl, setQuickCheckTargetUrl] = useState(() => getUrlWithNewCorrelationId(config.qk));
  const isRedirecting = useRef(false);

  const signalRedirect = () => {
    isRedirecting.current = true;
  };

  // NOTE:
  // *** The functionality below is brittle and relies on nuanced interactions between Spray Cans, teapot, and the browser. ***
  //
  // The core intent is that a new client correlation ID URL parameter is generated for QuickCheck every time a user clicks
  // the QuickCheck button.
  //
  // See https://confluence.kdc.capitalone.com/display/error418/CANCA-22480%3A+CCID+inconsistent+behaviour+on+mobile
  // for a detailed description of the data intent.
  //
  // There are 4 key events which happen a specific order when redirecting a user to QuickCheck:
  // 1. The user visits this QuickCheck welcome page and a new correlation ID is generated
  // 1. The user clicks the QuickCheck button (a link styled as a button)
  // 2. A "link click" Snowplow event is streamed (this is done by Spray Cans) containing the target_url where the user will be redirected
  //    - The target_url field must contain the new correlation ID to allow correlation to future QuickCheck events
  //    - The application_id field must contain the usual WWW correlation ID (not handled here) in order to allow correlation to past events
  // 3. The user is redirected to the QuickCheck target_url with the correct correlation ID as a URL parameter
  // 4. A new correlation ID is generated for the next time the user clicks the QuickCheck button
  //
  // As per the intent, a new correlation ID must be generated every time the user visits QuickCheck.
  // Intuitively, this would happen BEFORE the the event containing the target_url is sent AND before the user is redirected.
  // However, due to the way browsers handle events and the event streaming setup in Spray Cans, this is not possible.
  //
  // To ensure the proper ordering and data integrity, a new ID is generated AFTER the event stream and redirect,
  // by setting up an event listener on the window blur event and integrating it with a piece of ref state.
  // The assumption is that the window will be unfocused since the user is redirected to QuickCheck in a new tab.
  //
  // Hence, the next time the button is clicked the generated ID will be used and will be different from the previous click.
  useEffect(() => {
    const performPostRedirectActions = () => {
      if (isRedirecting.current) {
        setQuickCheckTargetUrl(getUrlWithNewCorrelationId(config.qk));
        newrelicPageAction('QuickCheckClick', {});

        isRedirecting.current = false;
      }
    };

    window.addEventListener('blur', performPostRedirectActions);

    return () => {
      window.removeEventListener('blur', performPostRedirectActions);
    };
  }, [config.qk]);

  const supportsIntersectionObserver =
    typeof window !== 'undefined' ? window.IntersectionObserver || 'IntersectionObserver' in window : undefined;
  const componentRef = React.useRef(null);
  const entry = useIntersectionObserver(componentRef, {});
  const isVisible = !!entry?.isIntersecting;
  const [isAnimating, setIsAnimating] = useState(false);

  const getContentKey = getContentKeyUtil('pages.quickcheck-redesign');

  const WhyQuickCheckSection = () => {
    return (
      <div className="why-quick-check-section-container" data-testid="why-quick-check-section">
        <h2>
          <FormattedMessage id="pages.quickcheck-redesign.why-quick-check.title" />
        </h2>
        <div className="info-item-container">
          <div className="info-item">
            <h3>
              <FormattedMessage
                id="pages.quickcheck-redesign.why-quick-check.info.p1"
                values={{ sup2: <sup>2</sup> }}
              />
            </h3>
            <p>
              <FormattedMessage id="pages.quickcheck-redesign.why-quick-check.info.p1-1" />
            </p>
          </div>
          <div className="info-item">
            <h3>
              <FormattedMessage
                id="pages.quickcheck-redesign.why-quick-check.info.p2"
                values={{ sup1: <sup>1</sup> }}
              />
            </h3>
            <p>
              <FormattedMessage id="pages.quickcheck-redesign.why-quick-check.info.p2-1" />
            </p>
          </div>
          <div className="info-item">
            <h3>
              <FormattedMessage id="pages.quickcheck-redesign.why-quick-check.info.p3" />
            </h3>
            <p>
              <FormattedMessage id="pages.quickcheck-redesign.why-quick-check.info.p3-1" />
            </p>
          </div>
        </div>
      </div>
    );
  };

  WhyQuickCheckSection.propTypes = {
    intl: intlShape,
  };

  const HowItWorksSection = ({ intl }) => {
    return (
      <div className="how-it-works-section-container" data-testid="how-it-works-section">
        <h2>{getContentKey(intl, 'how-it-works.title')}</h2>
        <IconSetPDP>
          <IconPDP
            caption=""
            subCaption={getContentKey(intl, 'how-it-works.p1')}
            icon={qkIconStepOne}
            iconAlt=""
            bolded="bottom"
          />
          <IconPDP
            caption=""
            subCaption={getContentKey(intl, 'how-it-works.p2')}
            subCaptionNowrap={getContentKey(intl, 'how-it-works.p2-1')}
            subCaptionSuper="1"
            icon={qkIconStepTwo}
            iconAlt=""
            bolded="bottom"
          />
          <IconPDP
            caption=""
            subCaption={getContentKey(intl, 'how-it-works.p3')}
            icon={qkIconStepThree}
            iconAlt=""
            bolded="bottom"
          />
        </IconSetPDP>
      </div>
    );
  };

  HowItWorksSection.propTypes = {
    intl: intlShape,
  };

  const MarketingSection = ({ intl }) => {
    return (
      <div className="qk-marketing-section-container" data-testid="qk-marketing-section">
        <TextBlock
          containerClassName="marketing-text"
          headerTag="h2"
          headerText={intl.formatMessage({ id: 'pages.quickcheck-redesign.marketing-section.title' })}
          contentText={
            <FormattedMessage
              id="pages.quickcheck-redesign.marketing-section.description"
              values={{ star: <sup>star</sup> }}
            />
          }
        />
        <img
          className="marketing-section-image"
          data-testid="qk-marketing-section-image"
          src={marketingSectionImage}
          alt=""
        />
      </div>
    );
  };

  MarketingSection.propTypes = {
    intl: intlShape,
  };

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (config.isReady) {
      const [currentDomain, currentQS] = quickCheckTargetUrl.split('?');
      const [freshDomain] = config.qk.split('?');
      const shouldAddFrenchQS = intl.locale === 'fr' && currentQS.indexOf(`lang=${LANGUAGES.FRENCH_CANADA}`) === -1;
      if (currentDomain !== freshDomain || shouldAddFrenchQS) {
        const searchParams = new URLSearchParams(window.location.search);
        let updatedUrl = getUrlWithNewCorrelationId(config.qk);
        if (shouldAddFrenchQS) {
          updatedUrl = getUrlWithNewCorrelationId(
            addQSIfNeeded({ url: config.qk, key: 'lang', value: `${LANGUAGES.FRENCH_CANADA}` }),
          );
        }
        searchParams.forEach((value, key) => {
          updatedUrl = addQSIfNeeded({
            url: updatedUrl,
            key,
            value,
          });
        });
        setQuickCheckTargetUrl(updatedUrl);
      }
    }
  }, [config.isReady, config.qk, quickCheckTargetUrl, intl.locale]);

  return (
    <QuickCheckLayoutLH>
      <SEOHelmet
        title={intl.formatMessage({ id: 'pages.quickcheck-redesign.page-title' })}
        description={intl.formatMessage({ id: 'pages.quickcheck-redesign.page-desc' })}
        lang={intl.locale}
      />
      <section className="page-content-wrapper qk-welcome-page-redesign" data-testid="welcome-page-main-wrapper">
        <div className="qk-hero-banner-section">
          <h1>
            <FormattedMessage
              id="pages.quickcheck-redesign.qk-cta-control"
              values={{
                rball: <sup className="dagger">®</sup>,
                star: <sup>*</sup>,
                MC: <sup className="dagger">MC</sup>,
                sup1: <sup>1</sup>,
              }}
            />
          </h1>
          <div className="qk-hero-banner-button" ref={componentRef}>
            <Link
              accessibilityLabel={intl.formatMessage({
                id: `pages.quickcheck-redesign.cta-alt`,
              })}
              newTab
              to={quickCheckTargetUrl}
              className="action button see-cards-button"
              onClick={signalRedirect}
            >
              <FormattedMessage id="pages.quickcheck-redesign.cta" />
            </Link>
          </div>
          <div className="qk-redesign-find-card page-content">
            <img
              data-testid="qk-hero-banner-image"
              className="qk-welcome-page-redesign-cards"
              src={QuickCheckCards}
              alt=""
            />
          </div>
        </div>
        <LayoutWrapper className="why-quick-check-section">
          <WhyQuickCheckSection />
        </LayoutWrapper>
        <LayoutWrapper className="how-it-works-section">
          <HowItWorksSection intl={intl} />
        </LayoutWrapper>
        <LayoutWrapper className="qk-marketing-section">
          <MarketingSection intl={intl} />
        </LayoutWrapper>
        <LayoutWrapper className="legal-disclaimer-layout-wrapper-product-suite">
          <section id="qk-legal-disclaimer-content" data-testid="qk-legal-disclaimer-section">
            <AccordionItem
              data-testid="qk-legal-info-accordion"
              title={<h2>{getContentKey(intl, `legal-disclaimer.title`)}</h2>}
              id="qk-legal-info-accordion"
              onFocus={() => {}}
              onBlur={() => {}}
              isIndependent
            >
              <div className="legal-context">
                <p>
                  <span className="quickcheck-pre-approval">
                    <FormattedMessage
                      id="pages.quickcheck-redesign.legal-disclaimer.info.p1"
                      values={{ sup1: <sup>1</sup> }}
                    />
                  </span>
                  <ol type="a">
                    <li>
                      <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-1" />
                    </li>
                    <li>
                      <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-2" />
                    </li>
                    <li>
                      <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-3" />
                    </li>
                    <li>
                      <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-4" />
                    </li>
                    <li>
                      <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-5" />
                    </li>
                  </ol>
                </p>
                <p>
                  <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p1-6" />
                </p>
                <p>
                  <span className="quickcheck-pre-approval">
                    <FormattedMessage
                      id="pages.quickcheck-redesign.legal-disclaimer.info.p2"
                      values={{ sup2: <sup>2</sup> }}
                    />
                  </span>
                  <div>
                    <FormattedMessage
                      id="pages.quickcheck-redesign.legal-disclaimer.info.p2-1"
                      values={{
                        boldText: (
                          <b>
                            <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p2-1-boldText" />
                          </b>
                        ),
                      }}
                    />
                  </div>
                </p>
                <p>
                  <div>
                    <FormattedMessage
                      id="pages.quickcheck-redesign.legal-disclaimer.info.p2-2"
                      values={{
                        boldText: (
                          <b>
                            <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p2-2-boldText" />
                          </b>
                        ),
                      }}
                    />
                    <FormattedMessage
                      id="pages.quickcheck-redesign.legal-disclaimer.info.p2-2-1"
                      values={{
                        boldText: (
                          <b>
                            <FormattedMessage id="pages.quickcheck-redesign.legal-disclaimer.info.p2-2-1-boldText" />
                          </b>
                        ),
                      }}
                    />
                  </div>
                </p>
              </div>
            </AccordionItem>
          </section>
        </LayoutWrapper>
      </section>
      {(!supportsIntersectionObserver || isAnimating) && (
        <div
          data-testid="qk-sticky-bar-button"
          className={`qk-stickybar-wrapper ${!isVisible ? 'visible' : 'hidden'} ${isAnimating ? 'animating' : ''}`}
        >
          <div className="qk-redesign-sticky-button">
            <Link
              accessibilityLabel={intl.formatMessage({
                id: `pages.quickcheck-redesign.cta-alt`,
              })}
              newTab
              to={quickCheckTargetUrl}
              className="button large action apply-now-button qk-redesign-sticky-button-cta"
              onClick={signalRedirect}
            >
              <FormattedMessage id="pages.quickcheck-redesign.cta" />
            </Link>
          </div>
        </div>
      )}
    </QuickCheckLayoutLH>
  );
});

export default QuickCheckRedesignComponent;
